.btn {
  padding: 8px;
  border-radius: 10px;
  min-width: 119px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  &.danger {
    background-color: #FE5C5C;
    color: #FFF;
    border: none;

    &.wire {
      background-color: transparent;
      color: #FE5C5C;
      border: 1px solid #FE5C5C;
    }
  }
}

.form-options-container,
.table-options-container {
  .btn.btn {
    border: 1px solid #ECECEC;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    letter-spacing: 0.6px;

    &.active {
      color: #FE5C5C;
      border: 1px solid #FE5C5C;
    }
  }
}
