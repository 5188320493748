.fieldset {
  padding-bottom: 34px;
}

input {
  border-radius: 10px;
  border: 1px solid #ECECEC;
  padding: 5px 10px;
  height: 24px;
  box-sizing: content-box;
  font-family: 'Manrope', sans-serif;
}

textarea {
  font-family: 'Manrope', sans-serif;
}

.select-overlay-container {
  position: relative;

  select {
    height: 36px;
    width: 100%;
    visibility: hidden;
  }

  .select-overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 10px;
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #ECECEC;
  }

  .arrow {
    margin-right: 10px;
  }
}

.select-overlay-container,
input {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.select-overlay-container.small,
input.small {
  width: calc(25% - 5px);
}

.select-overlay-container.normal,
input.normal {
  width: calc(50% - 5px);
}

.select-overlay-container.large,
input.large {
  width: calc(75% - 5px);
}

.select-overlay-container:not(:first-child),
input:not(:first-child) {
  margin-left: 10px;
}

.calendar-input {
  position: relative;

  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
  }
}

.form-fields {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 10px;
}

.form-field-container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.form-field-container.large {
  grid-column: span 12;
}

.form-field-container.normal {
  grid-column: span 6;
}

.form-field-container.small {
  grid-column: span 4;
}

.form-field-container.quarter {
  grid-column: span 3;
}

.form-field-container input:not(:first-child) {
  margin-left: 0;
}

.form-field-container {

  label {
    font-size: 10px;
    font-weight: 500;
    line-height: 24px; /* 240% */
    letter-spacing: 0.2px;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 4px;
  }

  input {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    width: 100%;
    box-sizing: border-box;
    height: 36px;
  }

  textarea {
    border-radius: 10px;
    border: 1px solid #ECECEC;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    padding-bottom: 34px;
  }

  .select-overlay-container {
    margin-left: 0;
    width: 100%;

    .arrow {
      opacity: 0.5;
    }

    &.selected {
      .select-overlay {
        color: #000;
      }
    }
  }

  .images-container {
    display: flex;
    column-gap: 12px;

    .mobile-checkbox {
      display: none;
    }
  }

  .image-container {
    border: 1px solid #EAEAEF;
    width: 132px;
    height: 134px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;

    .image {

    }

    .caption {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.5;
    }
  }

  .image-upload {
    border-radius: 10px;
    border: 1px dashed #EAEAEF;
    width: 132px;
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.5);
    row-gap: 4px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: -34px;
  }

  .editable-list-fields {
    display: grid;
    grid-template-columns: calc(48% - 5px) calc(48% - 5px) 4%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .label {
      font-size: 10px;
      font-weight: 500;
      line-height: 24px; /* 240% */
      letter-spacing: 0.2px;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .delete-container {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .add-more {
    margin-top: 10px;
    display: flex;
    height: 36px;
    width: 95px;
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    background: #EAEAEF;
    column-gap: 4px;
    cursor: pointer;

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
  }

  .searchable-multi-select {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-column-gap: 10px;

    .searchable-input-container {
      position: relative;

      .close-container {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .results-container {
      height: 195px;
      border-radius: 10px;
      border: 1px solid #ECECEC;
      margin-top: 10px;
      overflow: hidden;

      .result {
        padding: 6px 10px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;

        &.selected {
          background-color: rgba(254, 92, 92, 0.10);
          background-image: url('/images/checkmark.svg');
          background-repeat: no-repeat;
          background-position: 96% 8px;
        }
      }
    }

    .selected-grid {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5px));
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      .selected {
        border-radius: 10px;
        border: 1px solid #FE5C5C;
        background: rgba(254, 92, 92, 0.10);
        padding: 6px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #FE5C5C;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;

        .remove {
          cursor: pointer;
        }
      }
    }
  }

  .multi-select {
    .results-container {
      border-radius: 10px;
      border: 1px solid #ECECEC;
      margin-top: 10px;
      overflow: hidden;

      .result {
        padding: 6px 10px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;

        &.selected {
          background-color: rgba(254, 92, 92, 0.10);
          background-image: url('/images/checkmark.svg');
          background-repeat: no-repeat;
          background-position: 96% 8px;
        }
      }
    }
  }
}

@include mobile() {
  input {
    padding: 7px 10px;
  }

  .select-overlay-container {
    .select-overlay,
    select {
      height: 40px;
    }
  }

  .select-overlay-container.mobile-small,
  input.mobile-small {
    width: calc(25% - 5px);
  }

  .select-overlay-container.mobile-normal,
  input.mobile-normal {
    width: calc(50% - 5px);
  }

  .select-overlay-container.mobile-large,
  input.mobile-large {
    width: calc(75% - 5px);
  }

  .fieldset {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 44px;

    .form-field-container {
      &.small,
      &.normal,
      &.large {
        grid-column: span 12;
      }
    }
  }

  .images-container {
    flex-direction: column;
    row-gap: 24px;

    .image-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 40px;
      justify-content: flex-start;
      border: none;

      .image {
        height: 40px;
        width: 64px;
        object-fit: fill;
      }

      .caption {
        padding-left: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      .mobile-checkbox {
        display: block;
      }
    }

    .image-upload {
      width: 100%;
      height: 50px;
      flex-direction: row;
      column-gap: 12px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .form-field-container {
    .actions {
      padding-top: 40px;
      flex-direction: column;
      row-gap: 10px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .add-more {
      height: 40px;
      width: 100%;
    }

    .searchable-multi-select {
      grid-template-columns: 100%;

      > div {
        position: relative;
      }

      .searchable-input-container {
        .close {
          display: initial;
        }
      }

      .results-container {
        position: absolute;
        left: -2px;
        width: 90%;
        background-color: #FFF;
        z-index: 99;
      }
    }
  }

  .form-options-container {
    .btn {
      padding: 9px 8px;
    }
  }
}

