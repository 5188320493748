@mixin tablet () {
  @media (max-width: 1120px) {
    @content;
  }
}

@mixin mobile () {
  @media (max-width: 700px) {
    @content;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Manrope', sans-serif;
}

body.no-scroll {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@import "container";
@import "tables";
@import "forms";
@import "rich-editor";
@import "buttons";
