.filters-container .row {
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-content: center;
  padding: 10px 0;
  box-sizing: content-box;
}

.related {
  background-color: #F8F9FE;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  column-gap: 8px;

  a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }
}

.pagination-container {
  display: flex;
  align-content: center;
  column-gap: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.pagination-container div {
  display: flex;
  align-items: center;
}

.table-titles-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.rows-container {
  display: grid;
  width: 100%;
}

.mobile-rows-container {
  display: none;
}

.rows-container .table-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 12px;
}

.table-column {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 16px 18px 16px 0;
  cursor: pointer;
}

.posts-info {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.5;
  height: 44px;
  display: flex;
  align-items: center;
}

.table-options-container .related {
  display: none;
}

.table-container-inner {
  .table-body {
    .rows-container {
      .separator {
        height: 1px;
        background-color: #EAEAEF;
        margin-left: -18px;
        margin-right: -18px;
      }
    }
  }
}

.table-container-inner.full-width {
  .table-body {
    padding: 24px;

    .rows-container {
      padding-left: 18px;
      padding-right: 18px;

      .separator {
        margin-left: -42px;
        margin-right: -42px;
      }
    }
  }
}

@include tablet() {
  .table-container-inner {
    .table-body {
      .rows-container {
        .separator {
          display: none;
        }
      }
    }
  }
}

@include mobile() {
  .rows-container {
    display: none;
  }

  .table-body {
    padding-left: 0;
    padding-right: 0;
  }

  .filters-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-rows-container {
    display: block;

    .table-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid #EAEAEF;

      .table-column {
        padding: 12px 24px;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        &:first-child {
          grid-column: span 3;
          font-size: 16px;
          font-weight: 600;
        }

        .label {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0.5;
        }
      }
    }
  }

  .table-options-container {
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    padding: 24px 24px 0 24px;

    .related {
      display: flex;
    }

    .buttons {
      .btn {
        padding: 9px 8px;
      }
    }
  }
}
