.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1;
  position: relative;
  background-color: #F8F9FE;

  .form-container {
    max-width: 492px;
    width: 100%;

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 64px;
      column-gap: 12px;

      .header-logo-image {
        height: 42px;
        width: 54px;
      }

      .header-logo-text {
        height: 49px;
        width: 95px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      flex: 1;

      input {
        width: 100%;
        margin-left: 0;
        height: 52px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }

      .options {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #6C6C6C;

        .left {
          display: flex;
          align-items: center;
          column-gap: 12px;

          input {
            width: 24px;
            height: 24px;
            appearance: none;
            border: 1px solid #EAEAEF;
            padding: 0;
            border-radius: 6px;
          }
        }
      }

      .submit-container {
        display: flex;
        justify-content: center;

        .btn {
          padding-top: 14px;
          padding-bottom: 14px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  > .logo-container {
    position: absolute;
    bottom: 65px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  min-height: 100vh;
  background-color: #000;
  overflow-x: hidden;
  position: relative;

  .sidebar-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 247px;
    height: 100vh;
    background-color: black;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    transform: translateX(-231px);
    transition: 0.5s 0s ease-in-out;

    .sidebar {
      display: flex;
      padding: 32px 24px;
      flex-direction: column;
      color: #FFF;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      width: 0;

      .header-logo-image {
        margin-bottom: 48px;
      }

      .header-logo-text {
        display: none;
      }

      .links-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .links {
          display: flex;
          flex-direction: column;

          .link {
            padding: 16px;
            column-gap: 12px;
            display: flex;
            opacity: 0.5;

            .arrow {
              display: none;
            }
          }

          .active-group {
            background-color: rgba(255, 255, 255, 0.20);
            border-radius: 6px;
            padding: 8px;

            .link {
              padding: 8px;
              opacity: 1;

              &.child {
                margin-left: 36px;
                font-weight: 400;

                .material-symbols-outlined {
                  opacity: 0;
                }

                &.active {
                  color: #FE5C5C;
                }
              }
            }
          }
        }

        .logout {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 16px;
        }
      }
    }

    .sidebar-action {
      display: flex;
      height: 100%;
      width: 16px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &.active {
      transform: translateX(0px);

      .sidebar {
        width: calc(100% - 16px);
      }

      .sidebar-action {
        transform: rotate(0deg);
        transition: ease-in-out;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.header {
  height: 48px;
  padding: 24px 72px;
  display: flex;
  flex: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  color: #FFF;
  display: flex;
  align-items: center;
  height: 48px;
  flex-wrap: wrap;
  column-gap: 9px;
}

.header-logo a {
  height: 48px;
  display: flex;
  align-items: center;
}

.header-logo-image {
  width: 36px;
  height: 34px;
}

.header-logo-text {
  width: 62px;
  height: 32px;

  &.mobile {
    display: none;
  }
}

main > .breadcrumbs-container {
  display: none;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-size: 18px;
  font-weight: 600;
  line-height: 40px;

  .previous {
    opacity: 0.5;

    &.mobile {
      display: none;
    }
  }
}

.view-mode-container {
  background-color: #282828;
  display: flex;
  width: 88px;
  height: 48px;
  justify-content: space-around;
  align-items: center;
  border-radius: 100px;
}

.view-mode-icon-active,
.view-mode-icon {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.view-mode-icon-split {
  width: 22px;
  height: 18px;
}

.view-mode-icon-single {
  width: 20px;
  height: 18px;
}

.view-mode-icon-active {
  background-color: #FFF;
}

.table-container {
  background-color: #F8F9FE;
  display: flex;
  flex: 1;
  padding: 64px 55px;
  flex-direction: row;
  column-gap: 32px;
}

.table-options-container {
  background-color: #F8F9FE;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 32px;
  margin-bottom: -32px;
  z-index: 9;
  display: flex;
  justify-content: space-between;

  .buttons {
    display: flex;
    column-gap: 12px;
  }
}

.form-options-container {
  .buttons {
    display: flex;
    column-gap: 12px;
  }
}

.fieldset {
  .form-options-container {
    display: flex;
    column-gap: 12px;
    padding-bottom: 10px;
  }
}

.table-container-inner {
  //background-color: #FFF;
  display: flex;
  flex-shrink: 1;
  width: calc(50% - 16px);
  flex-direction: column;
  border-radius: 10px;

  &.card-container {
    background-color: #FFF;
  }

  .table-body {
    background-color: #FFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #EAEAEF;
  }

  &.full-width {
    width: 100%;

    .filters {
      .collapse {
        max-width: 310px;
        width: 100%;
      }

      .expand {
        flex: 1;
      }
    }
  }
}

.card-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
}

.card-table-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: calc(50% - 16px);
  background-color: #FFF;
}

.card-container .table-body {
  background-color: transparent;
  border: none;
}

.table-heading {
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px 18px;
  flex: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.table-heading .heading-text-container {
  color: #FFF;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 18px;
  font-weight: 600;
  padding: 6px 0;

  .previous {
    color: rgba(255, 255, 255, 0.5);
  }
}

.table-heading .close {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.table-body {
  padding: 18px;
}

.table-body .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  background-color: #FFF;
  grid-column-gap: 12px;
}

.card {
  background-color: #FFF;
  width: 100%;
  padding: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  row-gap: 88px;
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);

  .card-icon {
    .material-symbols-outlined {
      font-size: 40px;
    }
  }
}

.card .card-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
}

.card-arrow-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FE;
  border-radius: 100px;
}

.material-symbols-outlined {
  font-size: 24px;
}

.card-table .material-symbols-outlined {
  font-size: 40px;
}

.sidebar-header .close,
.mobile-forgot-password,
.pagination-container-mobile,
.mobile-menu-icon {
  display: none;
}

/* Tablet and Smaller Desktop */
@include tablet() {
  .login-container {
    padding-bottom: 140px;
  }

  .main {
    padding-left: 0;
  }

  .header {
    padding: 24px 40px;

    .breadcrumbs {
      display: none;
    }
  }

  main > .breadcrumbs-container {
    display: flex;
    justify-content: space-between;
    background-color: #F8F9FE;
    padding-top: 33px;
    margin-bottom: -1px;
    padding-left: 40px;
    padding-right: 40px;
    z-index: 9;

    .related {
      background-color: #EAEAEF;
      border-radius: 10px;
      padding: 10px 14px;
      line-height: 24px;
    }
  }

  main > .breadcrumbs-container .breadcrumbs {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px; /* 222.222% */
    letter-spacing: -0.36px;

    .previous {
      &.mobile {
        display: initial;
      }
    }
  }

  .header-logo-text {
    display: none;
  }

  .header-logo-text.mobile {
    display: initial;
  }

  .view-mode-container {
    display: none;
  }

  .mobile-menu-icon {
    display: initial;
    cursor: pointer;
  }

  .table-container {
    padding: 32px 40px;
    display: grid;
    grid-template-columns: 100% 100%;
    column-gap: 80px;
    overflow-x: hidden;
  }

  .card-table {
    grid-template-columns: repeat(2, 1fr);
  }

  .table-container-inner {
    width: 100%;

    &.desktop {
      // This is just to hide the second table when viewing templates for table and mobile.
      display: none;
    }

    .close {
      display: none;
    }

    &.card-container {
      background-color: #F8F9FE;

      .table-heading {
        display: none;
      }
    }

    &.current {

    }

    &.previous {
      display: none;
    }

    .table-body {
      .cards {
        background-color: #F8F9FE;
      }

      .filters-container {
        .desktop {
          display: none;
        }
      }

      .rows-container {
        .table-title {
          border-bottom: none;
        }
      }
    }
  }

  .table-options-container {
    padding: 24px 40px;

    .related {
      background-color: #EAEAEF;
      border-radius: 10px;
      padding: 10px 14px;
      line-height: 24px;
    }
  }

  .pagination-container-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 28px 0 20px 0;
  }

  .login-container {
    .header-logo-image {
      width: 54px;
      height: 42px;
    }

    .header-logo-text {
      display: initial;
      width: 95px;
      height: 49px;
    }

    .form-container {
      .logo-container {
        padding-bottom: 40px;
      }

      .form {
        .submit-container {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  .main {
    .sidebar-container {
      width: 100vw;
      transform: translateX(-100vw);
      overflow-y: scroll;

      .sidebar-action {
        display: none;
      }

      .sidebar {
        padding: 24px 40px;

        .sidebar-header {
          display: flex;
          justify-content: space-between;

          .close {
            display: initial;
          }
        }

        .logo-container {
          display: flex;
          column-gap: 9px;
        }

        .header-logo-text {
          display: initial;
        }

        .links-container {
          .links {
            .active-group {
              background-color: transparent;
              padding-right: 0;

              .link {
                padding-bottom: 12px;
                padding-right: 0;

                &:not(:first-child) {
                  padding-top: 12px;
                }

                &.child {
                  color: #6C6C6C;
                  margin-left: 0;

                  &.active {
                    color: #6C6C6C;
                  }
                }
              }
            }

            .link {
              font-size: 32px;
              font-weight: 700;
              line-height: 40px;
              display: flex;
              align-items: center;
              padding-bottom: 20px;
              padding-top: 20px;
              padding-right: 0;
              opacity: 1;

              &:not(.child) {
                justify-content: space-between;

                &.active {
                  .arrow {
                    transform: rotate(180deg);
                  }
                }
              }

              &.child {
                color: #6C6C6C;
                margin-left: 0;
                column-gap: 24px;
              }

              .material-symbols-outlined {
                display: none;
              }

              .arrow {
                display: initial;
              }
            }
          }

          .logout {
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            padding-top: 40px;
            padding-bottom: 40px;

            svg {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }
  }
}

/* Mobile */
@include mobile() {
  .header {
    padding: 24px;
  }

  .login-container {
    padding: 0 24px 60px;

    .form-container {
      max-width: 380px;
    }
  }

  .main {
    .sidebar-container {
      .sidebar {
        padding: 16px 24px;

        .sidebar-header {
          .logo-container {
            column-gap: 0;
          }

          .header-logo-image {
            height: 24px;

            margin-bottom: 24px;
          }

          .header-logo-text {
            height: 24px;
          }

          .close {
            width: 24px;
            height: 24px;
          }
        }

        .links-container {
          .links {
            .link {
              font-size: 24px;
              font-weight: 700;
              line-height: 40px;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }

          .logout {
            font-size: 24px;
            font-weight: 700;
            line-height: 40px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  main > .breadcrumbs-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;

    .related {
      flex: 0;
    }
  }

  .table-container {
    padding: 24px;
  }

  .card-table {
    grid-template-columns: 100%;
  }
  .card {
    flex-direction: row;
    justify-content: flex-start;

    .card-icon {
      display: flex;
      align-items: center;
    }

    .card-text {
      flex: 1;
      padding-left: 10px;
    }
  }

  .table-body {
    .cards {
      display: grid;
      grid-template-columns: 100%;
      grid-row-gap: 24px;
      width: 100%;
    }
  }

  .table-options-container {
    margin-bottom: -1px;
  }

  .login-container {
    .form-container {
      .form {
        .options {
          justify-content: center;
          .right {
            display: none;
          }
        }

        .mobile-forgot-password {
          display: flex;
          justify-content: center;
          color: #6C6C6C;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }

  .mobile-forgot-password {
    display: flex;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
